import React, { useState, useEffect } from 'react'
import Footer from '../../components/Footer'
import { ConfigProvider } from 'antd';
import NavBar from '../../components/NavBar'
import { getLocalCart, removeLocalCart} from '../../utils/HelperFunctions'
import { useNavigate } from 'react-router-dom'
import { Button} from 'antd'
import { getUserId } from '../../utils/HelperFunctions'
import api from '../../service/api'
import '../../styles/cart.css'
import '../../styles/cart_item.css'
const CheckOut = () => {
    const [cart, setCart] = useState()
    const [CLoading, setCLoading] = useState(false)
    const tempcart = getLocalCart()
    const navigate = useNavigate()
    useEffect(() => {
        const temp = getLocalCart()
        temp !== {}? setCart(temp) : setCart({})
    },[])

const subTotal = () => {
  let totalPrice = 0
  if ( tempcart === null || tempcart === {} || tempcart.items.length === 0 ) {
      return parseInt(totalPrice) 
  }
  else if (tempcart.items.length > 0) {
      tempcart.items.forEach(item => {
          totalPrice = parseInt(totalPrice) + parseInt(item.price)* parseInt(item.quantity)
      });
  }
  return parseInt(totalPrice)
}

const valueAddedTax = () => {
  return 0;
}

const confirm = () => {
    setCLoading(true)
    
    const order = {
            'wholeSalerId' : getUserId(),
            'coldRoomId' : cart.coldRoomId,
            'orderItems' : 
                cart.items.map(i => {
                    return {
                        'productTypeId' : i.typeId,
                        'quantity' : i.quantity
                    }
                })
        }
        api.post('/wholesaler/orders', order).then(response => {
            setCLoading(false)
            console.log(response.data)
            removeLocalCart()
            navigate('/confirmation', { state: { orderCode: response.data.newOrder.orderCode}})
        })
        .catch(err => {
            console.log(err)
            setCLoading(false)
            removeLocalCart()
        })
}
const goShopping = () => {
  navigate('/')
}
  return (
    <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
          <div>
      <NavBar/>
      <div className='cart_container'>
        <div className='item_list'>
          <h4 className='cart_title'>Order Summary</h4>
          <hr/>
          {                        
              tempcart === null || tempcart === {} || tempcart.items.length === 0 ?
              <div className='no_cart'>
                          <p>No item in your Truck</p>
                          <Button onClick={goShopping}>Continue Shopping</Button>
                </div>
                :
                <div className='items'>
                  {
                    tempcart.items.map(item => {
                      return <div className='item'>
                          <div className='cart_item' key={item.typeId}>
                            <img className='item_imagee' src={item.imageURL} alt=''/>
                            <div className='item_infoo'>
                                  <p className='info_text'>{item.productName}</p>
                                  <p className='info_text'>Type : {item.typeName}</p>
                                  <p className='info_text'>Quantity : {item.quantity}</p>
                                  <p className='info_text'>Price : {item.price} ETB/KG</p>
                            </div>    
                        </div>
                        <hr/>
                      </div>                      
                      })
                  }
                </div>     
          }
        </div>
        <div className='checkoutt'>
          
          <div className='total_price'>
            <p className='t_label'>Total</p>
            <p className='t_price'>{subTotal() + valueAddedTax()} ETB</p>
          </div>
          <hr/>
          <Button type='primary' className='make_order' loading={CLoading} disabled={tempcart === null || tempcart === {} || tempcart.items.length === 0} onClick={confirm}>Confirm Order</Button>
        </div>
      </div>
      <Footer/>
          </div>
        </ConfigProvider>
    
    
  )
}

export default CheckOut