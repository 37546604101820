import React from 'react'
import { ConfigProvider } from 'antd';
import NavBar from '../../components/NavBar'
import HeroImageList from './hero/HeroImageList'
import ProductList from './product/ProductList'
import ColdRoomList from './coldroom/ColdRoomList'
import AboutUsList from './about/AboutUsList'
import Footer from '../../components/Footer'
import '../../App.css'
import BottomBar from '../../components/BottomBar'
const Home = () => {
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'green',
        colorSecondary: 'orange'
      },
    }}
  >

    <div>
      <NavBar/>
      <HeroImageList/>
      <h2 className='label'>Our Products</h2>
      <ProductList/>
      <h2 className='label'>Our Cold Rooms</h2>
      <ColdRoomList/>
      <AboutUsList/>
      <Footer/>
      <BottomBar/>
    </div>

  </ConfigProvider>
    
  )
}

export default Home
