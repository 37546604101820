import React from 'react'
import { useLocation } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import NavBar from '../../components/NavBar'
import ProductDetail from './ProductDetail'
import { getProductId } from '../../utils/HelperFunctions'
import Footer from '../../components/Footer'
import BottomBar from '../../components/BottomBar'
const Product = () => {
  const {state} = useLocation()
  const id = getProductId()
  console.log(`idddddddd...${id}`)
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'green',
        colorSecondary: 'orange'
      },
    }}
  >
    <div>
      <NavBar/>
      <ProductDetail product_id={id}/>
      <Footer/>
      <BottomBar/>
    </div>
  </ConfigProvider>
    
  )
}

export default Product
