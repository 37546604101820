import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import NavBar from '../../components/NavBar'
import Footer from '../../components/Footer'
import { Layout, Menu, ConfigProvider } from 'antd'
import {UserOutlined} from '@ant-design/icons';
import '../../styles/account.css'
import Order from './Order'
import Profile from './Profile'
const Account = () => {
  const {token} = useSelector((state) => state.user)
  const [selected, setSelected] = useState(0)
  const content = [<Order/>, <Profile/>]
  const changeSelected = (e) => {
    const key = e.key
    setSelected(key)
  }
  return (

    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'green',
      },
    }}
  >
     <div>
      <NavBar/>
      <Layout className='account_container'>
      <Layout.Sider collapsed={false} theme='light' className='side_bar_container'>
        <Menu
          theme="light"
          onClick={changeSelected}
          defaultSelectedKeys={[selected]}
          items={[
            {
              key: 0,
              icon: <UserOutlined />,
              label: 'My Order',
            },
            {
              key: 1,
              icon: <UserOutlined />,
              label: 'My Profile',
            }
          ]}
        />
      </Layout.Sider>
      <Layout.Content className='content_container'>
        {content[selected]}
      </Layout.Content>
      </Layout>
      <Footer/>
    </div>
  </ConfigProvider>

   
  )
}

export default Account
