import React from 'react'
import '../../../styles/hero.css'
const HeroImage = () => {
  return (
    <div className='hero_container'>
      <img src='/hero_image/12.png' alt=''/>
    </div>
  )
}

export default HeroImage