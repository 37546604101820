import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../styles/searchItem.css'
import { setProductId } from '../../utils/HelperFunctions'

const Item = ({searchItem}) => {
    const navigate = useNavigate()
    const gotoDetail = () => {
      setProductId(searchItem.id)
      navigate('/product')
    }
    return (
      <div className='search_item_container' onClick={gotoDetail}>
        <div className='search_item_image'>
          <img src={searchItem.imageUrl}/>
        </div>
        <div className='search_item_name'>
          <p>{searchItem.name}</p>
        </div>
      </div>
    )
}

export default Item
