import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const OptionMenu = ({ orderId, CancelCallback, ViewDetailCallback, disabled }) => {

    const options = [
        {
            title: "View Detail",
            key: 1
        },
        {
            title: 'Cancel Order',
            key: 2
        }
      ]
    const ITEM_HEIGHT = 48;
    const [anchorEl, setAnchorEl] = useState(null);
    const [element, setElement] = useState(null)
    const open = Boolean(anchorEl);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleCancel = () => {
        setAnchorEl(null);
        CancelCallback(orderId)
    }
    const handleView = () => {
        setAnchorEl(null);
        ViewDetailCallback(orderId)
    }
    return (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpen}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={handleView}>
                ViewDetail
            </MenuItem>
            <MenuItem onClick={handleCancel} disabled={disabled}>
                Cancel
            </MenuItem>
          </Menu>
        </div>
    )
}

export default OptionMenu