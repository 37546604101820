import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import HeroImage from './HeroImage';
import '../../../styles/hero_list.css'
const HeroImageList = () => {
  return (
    <div className='hero_list_container'>
      <Slider dots={true} infinite={false} speed={500} slidesToShow={1} slidesToScroll={1}>
        <HeroImage/>
      </Slider>
    </div>
  )
}

export default HeroImageList
