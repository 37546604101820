import  React, { useState } from 'react';
import { signOut } from '../store/slices/Thunk';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUserData } from '../store/slices/Thunk';
import { getToken, getUserId } from '../utils/HelperFunctions';
import { MdAccountCircle } from 'react-icons/md'
import { Popover } from 'antd';

const AccountMenu = () => {
    const {token} = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleClick = (event) => {
    };

    const handleSignOut = () => {
        dispatch(signOut()).then(navigate('/'))
    }
    
      const handleMyProfile = () => {
        navigate('/profile')
      }
      
      const handleMyOrder = () => {
        navigate('/order')
      }

    const one = (<div className='account_menuu'>
        <Link to='/login'><p className='account_menu_item'>Sign In</p></Link>
        <hr/>
        <Link to='/sign-up'><p className='account_menu_item'> Sign Up</p></Link>
    </div>)
    const two = (<div className='account_menu'>
        <p className='account_menu_item' onClick={handleMyProfile}>My Profile</p>
        <hr/>
        <p className='account_menu_item' onClick={handleMyOrder}>My Order</p>
        <hr/>
        <p className='account_menu_item' onClick={handleSignOut}>Sign Out</p>
    </div>)
    const handleClose = () => {
       
    };

    if (getToken()) {
        console.log('hhhhhhh'+getToken())
        if(!token) {
          dispatch(fetchUserData(getUserId()))
        }
      }


    return (
    <Popover placement="topLeft" content={token? two : one} trigger="click">
        <MdAccountCircle className='bottom_menu_icon'/>
    </Popover>
    )
}

export default AccountMenu