import { createAsyncThunk } from '@reduxjs/toolkit'
import {getToken, getUserId, removeToken, removeUserId} from '../../utils/HelperFunctions';
import api from '../../service/api';


export const fetchUserData = createAsyncThunk('user/fetchUserData', async (payload) => {
        const response = await api.get(`/wholesaler/wholesalers/profile/${payload}`);
        return response.data;
});

export const signup = createAsyncThunk('user/signup', async (payload) => {
    const response = await api.post('/wholesaler/wholesalers', payload);
    return response.data
})

export const login = createAsyncThunk('user/login', async (payload) => {
    const response = await api.post('/wholesaler/auth/login', payload);
    return response.data;
    
});

export const signOut = createAsyncThunk('user/signOut', async () => {
    removeToken()
    removeUserId()
});

export const changeLanguage = createAsyncThunk('language/changeLanguage', async (payload) => {
    return payload
})