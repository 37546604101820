import React from 'react'
import { ConfigProvider } from 'antd'
import NavBar from '../../components/NavBar'
import BottomBar from '../../components/BottomBar'
import Order from '../account/Order'
import '../../styles/my_order.css'
const MyOrder = () => {
  return (
    <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
          <div>
      <NavBar/>
      <div className='my_order_container'>
       <Order />
      </div>
      <BottomBar/>
    </div>
        </ConfigProvider>
    
  )
}

export default MyOrder
