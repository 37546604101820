import React from "react";
import Login from '../pages/login';
import NoMatch from '../pages/404';
import Home from '../pages/home';
import PrivateRoute from './PrivateRoute';
import { Routes,Route} from "react-router-dom";
import Product from "../pages/product";
import Cart from '../pages/cart'
import CheckOut from "../pages/checkout";
import Account from "../pages/account";
import Signup from "../pages/signup";
import OrderConfiramtion from '../pages/OrderConfirmation'
import MyOrder from '../pages/MyOrder/MyOrder';
import MyProfile from '../pages/MyAccount/MyProfile'
import Search from "../pages/search";
const Switch = () => (
    <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/product" element={<Product/>}/>
        <Route exact path='/login' element={<Login/>}/>
        <Route exact path="/cart" element={<Cart/>}/>
        <Route exact path="/sign-up" element={<Signup/>}/>
        <Route exact path="/search" element={<Search/>}/>
        <Route path="/account" element={ <PrivateRoute redirectPath='/account'><Account/></PrivateRoute> }/>
        <Route path="/check-out" element={ <PrivateRoute redirectPath='/check-out'><CheckOut /></PrivateRoute>}/>
        <Route path="/confirmation" element={ <PrivateRoute redirectPath='/confirmation'><OrderConfiramtion/></PrivateRoute>}/>
        <Route path="/order" element={ <PrivateRoute redirectPath='/order'><MyOrder/></PrivateRoute>}/>
        <Route path="/profile" element={<PrivateRoute redirectPath='/profile'><MyProfile/></PrivateRoute>}/>
        <Route path="*" element={<NoMatch/>}/>
    </Routes>
);

export default Switch;