import React, { useState } from 'react'
import Box from '@mui/material/Box';
import { TiHome } from 'react-icons/ti'
import AccountMenu from './AccountMenu';
import { useNavigate } from 'react-router-dom';
import '../styles/bottom_bar.css'
import CartMenu from './CartMenu';
const BottomBar = () => {
    const navigate = useNavigate()
    const handleHome = () => {
        navigate('/')
    }

    const handleCart = () => {
        navigate('/cart')
    }
    return (
        <Box className='bottom_bar'>
            <div className='bottom_nav'>
                <TiHome className='bottom_menu_icon home_active' onClick={handleHome}/>
                <CartMenu className='bottom_menu_icon'/>
                <AccountMenu className='bottom_menu_icon'/>
            </div>
        </Box>
    )
    }

export default BottomBar
