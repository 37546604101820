import React, {useState, useEffect} from 'react';
import {login} from '../../store/slices/Thunk'
import { ConfigProvider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRedirectPath, getToken } from '../../utils/HelperFunctions';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Input, Button, Modal } from 'antd'
import '../../styles/login.css'
import { Link } from 'react-router-dom';

const Login = () => {
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCodeSent, setISCodeSent] = useState(false);
    const [forgotPhone, setForgotPhone] = ('')
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const {token, loading, success, error} = useSelector((state) => state.user);
    const path = location.state ? location.state : '/'
    if (success) navigate(path)
    
    
    const handleLogin = (e) => {
        e.preventDefault();
        const phoneNumber = '+251'+phone
        dispatch(login({phoneNumber, password}))
    }
    const handleForgotPassword = () => {
        setIsModalOpen(true)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        setISCodeSent(false)
    }
    const handleSubmit = () => {
        setISCodeSent(true)
    }
    const handleCodeSubmit = () => {

    }
    const renderButton = (buttonProps) => {
        return <button {...buttonProps}>Resend</button>;
      };
      const renderTime = (remainingTime) => {
        // return <span>{remainingTime} seconds remaining</span>;
      }
    return (
        
        <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
            <div className='login_container'>
            <h1>Sign in to your account</h1>
            <div className='login_form'>
                <p className={error? 'error_show' : 'error_hide'}>Phone number or password incorrect!</p>
                <form onSubmit={handleLogin}>
                    <div className='Login_input_group'>
                        <label>Phone Number</label>
                        <Input addonBefore="+251" placeholder='9********' onChange={(e) => setPhone(e.target.value)} value={phone} maxLength={9} minLength={9} required pattern='^[9]\d{8}'/>
                    </div>
                    <div className='Login_input_group'>
                        <label>Password</label>
                        <Input.Password
                        placeholder="***********"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password} minLength={8} required/>
                    </div>
                    <div className='remeber_forgot'>
                        <div className='remember'>
                            <input type='checkbox'/>
                            <label>Remember me</label>
                        </div>
                        <div className='forgot'>
                            <p>Forgot Password?</p>
                        </div>
                    </div>
                    <div className='login_submit'>
                        <Button className='sign_input' type='primary' htmlType="submit" loading={loading}>Sign In</Button>
                    </div>
                    <div className='sign_up'>
                        <p>Don't have an account? <Link className='link' to='/sign-up' state={path}>Create new account</Link></p>
                    </div>
                </form>
            </div>
                <Modal
                    open={isModalOpen}
                    title="Forgot Password"
                    onOk={handleSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>Submit</Button>
                    ]}
                >
                    <div className='change_password_input'>
                        <label>Please enter your phone number</label>
                        <Input  addonBefore="+251" className='change_password_label' placeholder='9********' onChange={(e) => setForgotPhone(e.target.value)} value={forgotPhone} maxLength={9} minLength={9} required/>
                    </div>        
                </Modal>

                <Modal
                    open={isCodeSent}
                    title="Forgot Password"
                    onOk={handleCodeSubmit}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>Cancel</Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>Submit</Button>
                    ]}
                >
                    <div className='change_password_input'>
                        <label>Enter 6 digit number we have sent to your phone</label>
                        <OTPInput autoFocus OTPLength={6} otpType="number"/>
                        <ResendOTP renderButton={renderButton} renderTime={renderTime} />
                    </div>        
                </Modal>
            </div>
        </ConfigProvider>
        
    )
}

export default Login
