
export const getToken = () => {
    return localStorage.getItem('token');
}

export const removeToken = () => {
    localStorage.removeItem('token');
}

export const setToken = (val) => {
    localStorage.setItem('token', val);
}

export const getLocalCart = () => {
    return JSON.parse(localStorage.getItem('cart'))
}

export const setLocalCart = (val) => {
    localStorage.setItem('cart', val)
}
export const removeLocalCart = () => {
    localStorage.removeItem('cart')
}

export const setProductId = (val) => {
    localStorage.setItem('productId', val)
}

export const getProductId = () => {
    return localStorage.getItem('productId')
}

export const setRedirectPath = (val) => {
    return localStorage.setItem('path', val)
}

export const getRedirectPath = () => {
    return localStorage.getItem('path')
}

export const getUserId = () => {
    return localStorage.getItem('userId')
}

export const setUserId = (val) => {
    localStorage.setItem('userId', val)
}

export const removeUserId = () => {
    localStorage.removeItem('userId')
}