import React, { useState } from 'react'
import { MdOutlineLanguage, MdAccountCircle } from 'react-icons/md'
import { FaSearch, FaTruck } from 'react-icons/fa'
import { BiSearch } from 'react-icons/bi'
import '../styles/nav.css'
import { Link, Route, useNavigate, createSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Dropdown, Menu, Input, Button } from 'antd'
import { getToken, getLocalCart, getUserId } from '../utils/HelperFunctions'
import { useDispatch } from 'react-redux'
import { fetchUserData, signOut,changeLanguage } from '../store/slices/Thunk'
import Search from '../pages/search'

const NavBar = () => {
  const {token, userData} = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchKey, setSearchKey] = useState('')

  const handleSignOut = () => {
    dispatch(signOut()).then(navigate('/'))
  }

  const handleMyAccount = () => {
    navigate('/account')
  }

  const items = [
    {
      label: 'ENG',
      key: '1',
      icon: <MdOutlineLanguage />,
    },
    {
      label: 'AM',
      key: '2',
      icon: <MdOutlineLanguage />,
    },
    {
      label: 'ORO',
      key: '3',
      icon: <MdOutlineLanguage />,
    }
  ]

  const handleMenuClick = (e) => {
    dispatch(changeLanguage(items.find(i => i.key === e.key).label))
  }

  

  const login_menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Link to='/login'>
              Sign In
            </Link>
          )
        },
        {
          key: '2',
          label: (
            <Link to='/sign-up'>
              Sign Up
            </Link>
          )
        }
      ]}/>)

  const account_menu = (
        <Menu
          items={[
            {
              key: '1',
              label: (
                <p onClick={handleMyAccount}>My Account</p>
              )
            },
            {
              key: '2',
              label: (
                <p onClick={handleSignOut}>Sign Out</p>
              )
            }
          ]}/>)
  const menuProps = {
    items,
    onClick: handleMenuClick
  }
  
  
  const getTotalCartItem = () => {
    let totalCartItem = 0
    if(getLocalCart()) {
      totalCartItem = getLocalCart().items.length
    }
    return totalCartItem
  }

  const onSearch = (value) => {
    navigate({
      pathname: "/search",
      search: createSearchParams({
          search: value
      }).toString()
  });
  }
  if (getToken()) {
    if(!token) {
      dispatch(fetchUserData(getUserId()))
    }
  }
  return (
    <div>
      <div className='nav_header'>
      <div className='nav_container'>
        <Link className='logo_link' to='/'>
          <h1 className='logo'>RENSYSENGINEERING</h1>
        </Link>
        
      <div className='search_container'>
        <Input.Search className='search_input' placeholder="Search pershiable products" onSearch={onSearch} enterButton={<Button type='primary' className='search_btn'><BiSearch className='search_icon'/></Button>}/>
      </div>
      <div className='nav_icons'>
        <Dropdown className='account_menu' overlay={token? account_menu : login_menu}>
          {token?
          <p className='logged_user'>Welcome <span>{userData.fName}</span></p>
          :
          <MdAccountCircle className='account_icon'/>
          }
        </Dropdown>
        <Link to='/cart'>
          <div className='cart nav_icon cart_menu'>
            <FaTruck/>
            <div className='circle'>
              <p className='circle_text'>{getTotalCartItem()}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
    </div>
    
    </div>
    
    
  )
}

export default NavBar
