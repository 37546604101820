import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import CartItem from './CartItem'
import '../../styles/cart.css'
import NavBar from '../../components/NavBar'
import { getLocalCart, setLocalCart } from '../../utils/HelperFunctions'
import Footer from '../../components/Footer'
import { Button } from 'antd'
const Cart = () => {
    const [cart, setCart] = useState()
    const tempcart = getLocalCart()
    const navigate = useNavigate()
    useEffect(() => {
        const temp = getLocalCart()
        temp !== {}? setCart(temp) : setCart({})
    },[])
    const updateQuantity = (product_id, object_id, new_quantity) => {
        tempcart.items.find(item => item.typeId === object_id && item.productId === product_id).quantity = new_quantity
        setLocalCart(JSON.stringify(tempcart))
        setCart(tempcart)
    }

    const deleteProdcut = (product_id, object_id) => {
        tempcart.items = tempcart.items.filter(item => item.typeId !== object_id && item.productId !== product_id)
        setLocalCart(JSON.stringify(tempcart))
        setCart(tempcart)
    }

    const subTotal = () => {
        let totalPrice = 0
        if ( tempcart === null || tempcart === {} || tempcart.items.length === 0 ) {
            return parseInt(totalPrice)
        }
        else if (tempcart.items.length > 0) {
            tempcart.items.forEach(item => {
                totalPrice = parseInt(totalPrice) + parseInt(item.price)* parseInt(item.quantity)
            });
        }
        return parseInt(totalPrice)
    }

    const valueAddedTax = () => {
        return 0;
    }

    const checkOut = () => {
        navigate('/check-out')
    }

    const goShopping = () => {
        navigate('/')
    }

    return (
        <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
            <div>
                <NavBar/>
                <div className='cart_container'>
                    <div className='item_list'>
                    <p className='cart_title'>Your Shopping Truck</p>
                    <hr/>
                    {
                        
                        tempcart === null || tempcart === {} || tempcart.items.length === 0 ?
                        <div className='no_cart'>
                            <p>No item in your Truck</p>
                            <Button onClick={goShopping}>Continue Shopping</Button>
                        </div>
                        :
                        <div className='items'>
                            {
                                tempcart.items.map(item => {
                                    return <div className='item' key={item.typeId}>
                                            <CartItem item={item} quantityUpdate={updateQuantity} productDelete={deleteProdcut}/>
                                            <hr/>
                                        </div>
                                })
                            }
                        </div>
                    }
                    </div>
                    <div className='checkout'>
                        <p className='total_item'>Order Summary</p>
                        <hr/>
                        <div className='total_price'>
                            <p className='t_label'>Subtotal</p>
                            <p className='t_price'>{subTotal()} ETB</p>
                        </div>
                        <hr/>
                        <div className='total_price'>
                            <p className='t_label'>Value Added Tax</p>
                            <p className='t_price'>{valueAddedTax()} ETB</p>
                        </div>
                        <hr/>
                        <div className='total_price'>
                            <p className='t_label order_total'>Order total</p>
                            <p className='t_price order_total'>{parseInt(subTotal()) + parseInt(valueAddedTax())} ETB</p>
                        </div>
                        <div className='make_order_container'>
                        <Button type='primary' className='make_order' disabled={tempcart === null || tempcart === {} || tempcart.items.length === 0} onClick={checkOut}>CheckOut</Button>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </ConfigProvider>
        
    )
}
export default Cart