import React from 'react'
import { ConfigProvider } from 'antd'
import Profile from '../account/Profile'
import BottomBar from '../../components/BottomBar'
import NavBar from '../../components/NavBar'
import '../../styles/my_profile.css'
const MyProfile = () => {
  return (
    <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
          <div>
      <NavBar/>
      <div className='profile_container'>
        <Profile/>
      </div>
      <BottomBar/>
    </div>
        </ConfigProvider>
    
  )
}

export default MyProfile
