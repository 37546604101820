import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Input, Modal, Button, ConfigProvider } from 'antd'
import { getUserId } from '../../utils/HelperFunctions';
import api from '../../service/api';
const Profile = () => {
    const {userData} = useSelector((state) => state.user);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const password_change_err = document.getElementById('password_change_err')
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleSubmit = () => {
        
        if(oldPassword === '') {
            password_change_err.textContent = 'Please enter your old password'
            setIsError(true)
        }
        else if( newPassword ==='') {
            password_change_err.textContent = 'Please enter your new password'
            setIsError(true)
        }
        else if(confirmPassword === '') {
            password_change_err.textContent = 'Please confirm your new password'
            setIsError(true)
        }
        else if(confirmPassword !== newPassword) {
            password_change_err.textContent = 'Confirmed password does not match'
            setIsError(true)
        }
        else {
            const payload = {
                oldPassword: oldPassword,
                newPassword: newPassword
            }
            setLoading(true);
            api.post(`/wholesaler/auth/change-password/${getUserId()}`,payload).then(response => {
                setLoading(false)
                setIsModalOpen(false);
                setOldPassword('')
                setNewPassword('')
                setConfirmPassword('')
                setIsError(false)
                setSuccessOpen(true)
            }).catch(err => {
                setLoading(false)
                setIsError(true)
                password_change_err.textContent = 'Old password is incorrect!'
            })
        }
      }
      const handleCancel = () => {
        setIsModalOpen(false);
        setOldPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setIsError(false)
      };
      const handleSuccessOk = () => {
        setSuccessOpen(false)
      }
      const handleSuccessCancel = () => {
        setSuccessOpen(false)
      }
    return (
      <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
          <div className='profile_conatainer'>
            <h1 className='tab_title'>My Profile</h1>
            <div>
                <p className='account_info'>Name : {userData.fName+' '+userData.lName}</p>
                <p className='account_info'>Phone Number : {userData.phoneNumber}</p>
                <Button className='ch_button' onClick={showModal}>Change Password</Button>
            </div>
            <Modal
            open={isModalOpen}
            title="Change Password"
            onOk={handleSubmit}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
                Submit
              </Button>
            ]}
          >
            <div className='change_password_input'>
                <label>Old Password</label>
                <Input.Password id='old_phone' className='change_password_label' placeholder='*********' onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} minLength={9} required/>
            </div>
            <div className='change_password_input'>
                <label>New Password</label>
                <Input.Password id='new_phone' className='change_password_label' placeholder='*********' onChange={(e) => setNewPassword(e.target.value)} value={newPassword} minLength={9} required/>
            </div>
            <div className='change_password_input'>
                <label>Confirm</label>
                <Input.Password id='confirm_phone' className='change_password_label' placeholder='*********' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} minLength={9} required/>
            </div>
                <p id='password_change_err' className={isError? 'error' : 'noerror'}></p>            
            </Modal>
            <Modal open={successOpen} title="Change Password" onOk={handleSuccessOk} onCancel={handleSuccessCancel}>
                <p>Password Change Successfully</p>
            </Modal>
        
          </div>
      </ConfigProvider>
        
  )
}

export default Profile
