import { createSlice } from '@reduxjs/toolkit';
import {changeLanguage} from './Thunk';

const initialState = {
    language: 'ENG'
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
          .addCase(changeLanguage.fulfilled, (state, action) => {
            const lang = action.payload;
            state.language = lang;
          })   
      }
})


export const {} = languageSlice.actions;

export default languageSlice.reducer;