import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Product from './Product'
import '../../../styles/product_list.css'
import api from '../../../service/api';
const ProductList = () => {
  const [products, setProducts] = useState([])
  const [productListLoading, setProductListLoading] = useState(true)
  useEffect(() => {
    const getAllProducts = async () => {
     await api.get('/wholesaler/wholesalerProduct').then(response => {
        setProducts(response.data)
        setProductListLoading(false)
      }).catch(() => {
         setProducts([])
        setProductListLoading(false)
      })
    }
    getAllProducts()
  }, [])

  return (
    <div className='product_list'>
      <div className='product_list_container'>
        {
           productListLoading ?
           <div className='product_list_skeleton_container'>
            <Stack spacing={1} className='skeleten_item'> 
              <Skeleton animation="wave" variant="rounded" width='90%' height={130} />
              <Skeleton animation="wave" variant="rounded" width='90%' height={15} />
              <Skeleton animation="wave" variant="rounded" width='50%' height={15} />
            </Stack>
            <Stack spacing={1} className='skeleten_item'> 
              <Skeleton animation="wave" variant="rounded" width='90%' height={130} />
              <Skeleton animation="wave" variant="rounded" width='90%' height={15} />
              <Skeleton animation="wave" variant="rounded" width='50%' height={15} />
            </Stack>
            <Stack spacing={1} className='skeleten_item'> 
              <Skeleton animation="wave" variant="rounded" width='90%' height={130} />
              <Skeleton animation="wave" variant="rounded" width='90%' height={15} />
              <Skeleton animation="wave" variant="rounded" width='50%' height={15} />
            </Stack>
            <Stack spacing={1} className='skeleten_item'> 
              <Skeleton animation="wave" variant="rounded" width='90%' height={130} />
              <Skeleton animation="wave" variant="rounded" width='90%' height={15} />
              <Skeleton animation="wave" variant="rounded" width='50%' height={15} />
            </Stack>
            <Stack spacing={1} className='skeleten_item'> 
              <Skeleton animation="wave" variant="rounded" width='90%' height={130} />
              <Skeleton animation="wave" variant="rounded" width='90%' height={15} />
              <Skeleton animation="wave" variant="rounded" width='50%' height={15} />
            </Stack>
           </div>
            
           :
           products.length > 0 ? 
          <Slider dots={true} infinite={false} speed={500} slidesToShow={6} slidesToScroll={1} className="slider" responsive={
            [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  infinite: false,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  initialSlide: 4
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              }
            ]
          }>
            {
              products.map(product => {
                return <Product image={product.imageUrl} name={product.name} product_id={product.id} key={product.id}/>
              })
            }
  
          </Slider>
          :
          <p className='no_product'>Sorry we are out of stock for now. Please come back later!</p>
        }
        
    </div>
    </div>
    
  )
}

export default ProductList
