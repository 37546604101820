import React, { useState } from 'react'
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet'
import { getPreciseDistance } from 'geolib'
import '../../../styles/cold_room.css'
import axios from 'axios'
const ColdRoom = ({ position, name}) => {
    const [distance, setDistance] = useState('')
    const [currentLat, setCurrentLat] = useState('')
    const [currentLong, setCurrentLong] = useState('')
    const url ='https://maps.googleapis.com/maps/api/distancematrix/json?'
    const onSuccess = (pos) => {
        setCurrentLat(pos.coords.latitude)
        setCurrentLong(pos.coords.longitude)
        const g = getPreciseDistance(
            {
                latitude: parseFloat(pos.coords.latitude),
                longitude: parseFloat(pos.coords.longitude)
        
            }, 
            {
                latitude: parseFloat(position[0]),
                longitude: parseFloat(position[1])
            })

        setDistance(Math.round(g/1000))
    }

    const onError = () => {
        setDistance('')
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError)
    return (
        <div className='cold_room_container'>
            <MapContainer className='map' center={position} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position}>
                <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                </Popup>
                </Marker>
                <div className='on_map'>
                    <div className='direction'>
                        <a href={`https://www.google.com/maps/dir/${currentLat},${currentLong}/${position[0]},${position[1]}`} target='_blank'>Get Direction</a>
                    </div>
                </div>
            </MapContainer>
            <div className='cold_room_label'>
                <p className='name'>{name}</p>
                
                <p className='distace'>{distance}KM away</p>
            </div>
        </div>
    )
}

export default ColdRoom
