import React, { useEffect, useState } from 'react'
import '../../styles/product_detail.css'
import { Radio, Button, Modal, InputNumber } from 'antd';
import { FaTruck } from 'react-icons/fa';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import 'antd/dist/reset.css';
import api from '../../service/api';
import { getLocalCart, removeLocalCart, setLocalCart } from '../../utils/HelperFunctions';
import { useNavigate } from 'react-router-dom';

const ProductDetail = ({product_id}) => {
    const navigate = useNavigate()
    const [selectedType, setSelectedType] = useState()
    const [selectedPrice, setSelectedPrice] = useState('')
    const [selectedProductId, setSelectedProductId] = useState()
    const [product, setProduct] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [truckOpen, setTruckOpen] = useState(false)
    const [existOpen, setExistOpen] = useState(false)
    const [coldroomChangeOpen, setColdroomChangeOpen] = useState(false)
    const [ProductDetailLoading, setProductDetailLoading] = useState(true)

    useEffect(() => {
        const getProduct = async () => {
            await api.get(`/wholesaler/wholesalerProduct/${product_id}`).then(response => {
                setProduct(response.data)
                setSelectedProductId(response.data.id)
                setSelectedType(response.data.type[0].id)
                setProductDetailLoading(false)
            }).catch(error => {
                console.log(error)
            })
            
        }
        getProduct()
    }, [product_id])

    const changeType = (e) => {
        setSelectedType(e.target.value)
        setSelectedPrice('')
    }

    const changePrice = (price_id) => {
        setQuantity(1)
        setSelectedPrice(price_id)
    }

    const changeQuantity = (value) => {
        setQuantity(value)
    }

    const addToTruck = () => {
        let cart = getLocalCart()
        if(cart == null || cart === undefined || Object.keys(cart).length === 0) {
            console.log(product)
            let tempCart = {
                coldRoomId : selectedPrice,
                items : [
                    {
                        productId: product.id,
                        productName: product.name,
                        typeId: selectedType,
                        typeName: product.type.find(t => t.id === selectedType).title,
                        coldRoomName: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).name,
                        price: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).price,
                        quantity: quantity,
                        maximumQuantity: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity,
                        imageURL: product.type.find(t => t.id === selectedType).image
                    }
                ]
            }
            setLocalCart(JSON.stringify(tempCart))
            setTruckOpen(true)      
        }
        else if( cart.items.length === 0) {
            let tempCart = {
                coldRoomId : selectedPrice,
                items : [
                    {
                        productId: product.id,
                        productName: product.name,
                        typeId: selectedType,
                        typeName: product.type.find(t => t.id === selectedType).title,
                        coldRoomName: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).name,
                        price: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).price,
                        quantity: quantity,
                        maximumQuantity: 20,
                        imageURL: product.type.find(t => t.id === selectedType).image
                    }
                ]
            }
            console.log(tempCart)
            setLocalCart(JSON.stringify(tempCart))
            setTruckOpen(true)
            
        }
        else {
            
            if(cart.coldRoomId !== selectedPrice) {
                setColdroomChangeOpen(true)
            }
            else if(cart.items.find(i => i.productId === selectedProductId) && cart.items.find(i => i.typeId === selectedType)) {
                setExistOpen(true)
            }
            else {
                let tempCart = getLocalCart()
                tempCart.items.push(
                    {
                        productId: product.id,
                        productName: product.name,
                        typeId: selectedType,
                        typeName: product.type.find(t => t.id === selectedType).title,
                        coldRoomName: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).name,
                        price: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).price,
                        quantity: quantity,
                        maximumQuantity: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity,
                        imageURL: product.type.find(t => t.id === selectedType).image
                    }
                )
                setLocalCart(JSON.stringify(tempCart))
                setTruckOpen(true)
            }
        }
    }

    const buyNow = () => {
        let tempCart = {
            coldRoomId : selectedPrice,
            items : [
                {
                    productId: product.id,
                    productName: product.name,
                    typeId: selectedType,
                    typeName: product.type.find(t => t.id === selectedType).title,
                    coldRoomName: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).name,
                    price: product.type.find(t => t.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).price,
                    quantity: quantity,
                    maximumQuantity: 20,
                    imageURL: product.type.find(t => t.id === selectedType).image
                }
            ]
        }
        setLocalCart(JSON.stringify(tempCart))
        navigate('/check-out')

    }
    
    const handleExistCancel = () => {
        setExistOpen(false)
    }

    const updateExistTruck = () => {
        setExistOpen(false)
        let existTempCart = getLocalCart()
        existTempCart.items.splice(existTempCart.items.findIndex(item => item.productId === product.id && item.typeId === selectedType),1)
        setLocalCart(JSON.stringify(existTempCart))
        addToTruck()
    }
    
    const handleChangeColdroomCancel = () => {
        setColdroomChangeOpen(false)
    }

    const updateTruckColdroom = () => {
        setColdroomChangeOpen(false)
        removeLocalCart()
        addToTruck()
    }

    const go_shopping = () => {
        navigate('/')
    }

    const go_truck = () => {
        navigate('/cart')
    }

    return (
        <div className='product_detail_container'>

            {
                ProductDetailLoading ?
                <div className='product_detail_loading_container'>
                    <div className='product_image_loading'>
                    <Stack spacing={1} className='skeleten_item'>
                        <Skeleton animation="wave" variant="rounded" width="100%" height={340} />
                    </Stack>
                        
                    </div>
                    <div className='product_info_loading'>
                        <Stack spacing={1} className='skeleten_item'>
                            <Skeleton animation="wave" variant="reactangle" width='100%' height={20} />
                            <div className='part_loading'>
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                            </div>
                            <div className='part_loading'>
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={10} />
                            </div>
                            <Skeleton animation="wave" variant="reactangle" width='100%' height={10} />
                            <Skeleton animation="wave" variant="reactangle" width='100%' height={230} />
                            <div className='part_loading'>
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={20} />
                                <Skeleton className='part' animation="wave" variant="reactangle" width='100%' height={20} />
                            </div>
                        </Stack>
                    </div>
                </div>
                :
                <div className='product_details'>
                    <div className='product_detail_container'>
                            <div className='product_image'>
                                <img src={product.type.find(types => types.id === selectedType).image} alt=''/>
                            </div>
                            <div className='product_detail'>
                                <div className='product_info'>
                                    <h2 className='product_name'>{product.name}</h2>
                                    <div className='product_type'>
                                        <h3 className='title'>Type</h3>
                                        <div className='type_container'>
                                            <Radio.Group className='radio_group' onChange={changeType} value={selectedType}>
                                                {
                                                    product.type.map(types => {
                                                        return <div className='type' key={types.id}>
                                                            <Radio className='type_radio' value={types.id} defaultChecked={selectedType === types.id}>{types.title}</Radio>
                                                        </div>
                                                        
                                                    })
                                                }
                                            </Radio.Group>
                                        </div>
                                    </div>
                                    <div className='product_price'>
                                        <h3 className='title'>Price</h3>
                                        <div className='price_container'>
                                            {
                                                product.type.find(types => types.id === selectedType).coldRoomPrice.map(price => {
                                                    return <div className={ selectedPrice === price.coldRoomId ? 'price_active price': 'price' } key={price.coldRoomId} onClick={ () => changePrice(price.coldRoomId)}>
                                                        <p className='cold_room_name'>{price.name}</p>
                                                        <p className='price_amount'>{price.price} ETB/KG</p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='product_quantity'>
                                        <h3 className='title'>Quantity</h3>
                                        { 
                                        selectedPrice ===null || selectedPrice==='' ?
                                        <InputNumber className='quantity_input' addonAfter='KG' defaultValue={100} onChange={changeQuantity} value={quantity}/>
                                        :
                                            product.type.find(types => types.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity > 0 ?
                                                <div className='q_av'>
                                                    <InputNumber className='quantity_input' addonAfter='KG' defaultValue={100} onChange={changeQuantity} value={quantity} min={1} max={product.type.find(types => types.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity}/>
                                                    <p className='av'>Available: {product.type.find(types => types.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity} KG</p>
                                                </div>
                                                
                                            :
                                            <div className='product_quantity_container quantity_out_stock'>
                                                <p>Out of Stock</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='product_description'>
                                        <h2 className='title'>Description</h2>
                                        <p className='description'>{product.type.find(types => types.id === selectedType).description}</p>
                                    </div>                               
                                </div>
                                <div className='action_to_order'>
                                    <Button type='primary' className='detail_btn add_to_truck' onClick={() => addToTruck()} disabled={selectedPrice ===null || selectedPrice==='' || product.type.find(types => types.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity < 1}><FaTruck /> <p className='add_to_cart_text'>Add to Truck</p></Button>
                                    <Button className='detail_btn buy_now' onClick={() => buyNow()} disabled={selectedPrice ===null || selectedPrice==='' || product.type.find(types => types.id === selectedType).coldRoomPrice.find(p => p.coldRoomId === selectedPrice).quantity < 1}>Buy Now</Button>
                                </div>
                            </div>
                    </div>
                    <Modal
                        open={truckOpen}
                        title="Product added to truck successfuly"
                        onOk={go_truck}
                        onCancel={go_shopping}
                        footer={[
                        <Button key="back" onClick={go_shopping}>
                            No
                        </Button>,
                        <Button type='primary' key="submit" onClick={go_truck}>
                            Yes
                        </Button>
                        ]}
                    > 
                    <p>Do you want to go to checkout?</p>           
                    </Modal>

                    <Modal
                        open={existOpen}
                        title="Product already exist in your truck!"
                        onOk={updateExistTruck}
                        onCancel={handleExistCancel}
                        footer={[
                        <Button key="back" onClick={handleExistCancel}>
                            No
                        </Button>,
                        <Button type='primary' key="submit" onClick={updateExistTruck}>
                            Yes
                        </Button>
                        ]}
                    > 
                    <p>Do you want to update the existing product in your truck?</p>           
                    </Modal>

                    <Modal
                        open={coldroomChangeOpen}
                        title="Cold room mismatch"
                        onOk={updateTruckColdroom}
                        onCancel={handleChangeColdroomCancel}
                        footer={[
                        <Button key="back" onClick={handleChangeColdroomCancel}>
                            No
                        </Button>,
                        <Button type='primary' key="submit" onClick={updateTruckColdroom}>
                            Yes
                        </Button>
                        ]}
                    > 
                    <p>Do you want to update your truck with new cold room?</p>           
                    </Modal>
                </div>
            }

                  
        </div>
    )
}
export default ProductDetail