import React, { useEffect, useState } from 'react'
import ColdRoom from './ColdRoom'
import api from '../../../service/api'
import '../../../styles/cold_room_list.css'

const ColdRoomList = () => {
  const [coldrooms, setColdrooms] = useState([])
  useEffect(() => {
    const getAllColdrooms = async () => {
      const response = await api.get('/wholesaler/coldRoom').then(response => {
        setColdrooms(response.data)
      }).catch(error => {
        setColdrooms([])
      });
    }
    getAllColdrooms();
  },[])
  return (
    <div>
      {
      coldrooms.length > 0 ? 
        <div className='cold_room_list_container'>
          { 
            coldrooms.map(coldroom => {
              return <ColdRoom position={[coldroom.latitude, coldroom.longitude]} name={coldroom.name} key={coldroom.id}/>
            })
          }
        </div>
      :
      <p className='no_cold_room'>We are constructing coldrooms in different regions. We will post on our social media when we are ready to open.</p>
    }
    </div>
  )
}

export default ColdRoomList
