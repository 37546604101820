import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ConfigProvider } from 'antd';
import '../../styles/order_confirmation.css'
import { Button } from 'antd'
import { ImCheckboxChecked } from 'react-icons/im'
const OrderConfiramtion = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {orderCode} = state
  const goShopping = () => {
    navigate('/')
  }
  return (
    <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
            <div className='confirmation_container'>
              <p className='confirm_text title_one'>Your order has been received</p>
              <ImCheckboxChecked className='confirmed_icon'/>
              <p className='confirm_text title_two'>Thank you for purchase</p>
              <p className='confirm_text title_three'>Your order code is : {orderCode}</p>
              <p className='confirm_text title_three'>You will a get a phone call from us to guide you on payment and delivery</p>
              <Button className='back_to' onClick={goShopping}>Continue Shopping</Button>
          </div>
        </ConfigProvider>
    
  )
}

export default OrderConfiramtion
