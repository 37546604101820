import React from 'react'
import '../../../styles/about_us.css'
const AboutUs = ({ image, title, description}) => {
  return (
    <div className='about_us_container'>
      <img src={image} alt=''/>
      <p className='about_title'>{title}</p>
      <p className='about_desc'>{description}</p>
    </div>
  )
}

export default AboutUs
