import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { BiSearchAlt } from 'react-icons/bi'
import PropTypes from 'prop-types'
import { ConfigProvider } from 'antd'
import Item from './Item'
import NavBar from '../../components/NavBar'
import BottomBar from '../../components/BottomBar'
import '../../styles/search.css'
import { useEffect } from 'react'
import { useState } from 'react'
import api from '../../service/api'
const Search = () => {
  const [searchParam, setSearchParam] = useSearchParams()
  const [searchResult, setSearchResult] = useState([])

  useEffect(() => {
    const searchProduct = async () => {
      await api.get(`/wholesaler/wholesalerProduct?search=${searchParam.get('search')}`).then(response => {
        setSearchResult(response.data)
      }).
      catch(error => {
        setSearchResult([ ])
      })
    }
    searchProduct()
  }, [searchParam])
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'green',
        colorSecondary: 'orange'
      },
    }}
  >
    <div>
      <NavBar/>
      <div className='search_page_container'>
        <div className='search_heading'>
          <p className='heading_text'>Search result for {searchParam.get('search')}</p>
          <hr/>
        </div>
        {
          searchResult === null || searchResult.length === 0 || searchResult === undefined ?
          <div className='no_search'>
            <BiSearchAlt className='no_search_icon'/>
            <p className='no_search_title'>No result found</p>
            <p className='no_search_detail'>Sorry we couldn't found anything that match your search</p>
          </div>
          :
          <div className='search_item_list'>
            {
              searchResult.map( searchItem => {
                return <Item searchItem={searchItem}/>
              })
            }
          </div>
        }
      </div>
      <BottomBar/>
    </div>
  </ConfigProvider>
    
  )
}


export default Search
