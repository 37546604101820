import React from 'react'
import '../styles/footer.css'
import { FaFacebook,FaTwitter,FaTelegram,FaYoutube, FaLinkedin } from 'react-icons/fa'
const Footer = () => {
  return (
    <div className='footer_container'>
      <div className='footer_menu'>
        <p className='footer_menu_item'>Terms & Condition</p>
        <p className='footer_menu_item'>Privacy Policy</p>
        <p className='footer_menu_item'><a href='https://rensysengineering.com:2083/cpsess1886537157/frontend/jupiter/filemanager/showfile.html?file=Wholesaler+User+Manual.pdf&fileop=&dir=%2Fhome%2Frensysengineerin%2Fcoldroom.com%2Fhero_image&dirop=&charset=&file_charset=&baseurl=&basedir='>User Manual</a></p>
        <p className='footer_menu_item'>FAQ</p>
        <p className='footer_menu_item'>Contact Us</p>
      </div>
      <div className='social_links'>
        <a className='social' href='https://www.facebook.com/rensysengineering8544' target='_blank'><FaFacebook/></a>
        <a className='social' href='#' target='_blank'><FaTwitter/></a>
        <a className='social' href='#' target='_blank'><FaYoutube/></a>
        <a className='social' href='#' target='_blank'><FaLinkedin/></a>
      </div>
      <div className='developer_info'>
        &copy; 2022 Rensys Engineering. All rights reserved.
      </div>
    </div>
  )
}

export default Footer
