import './App.css';
import { Provider } from 'react-redux'
import store from './store';
import Switch from './routes';
function App() {
  return (
    <Provider store={store}>
      <Switch/>
    </Provider>
  );
}

export default App;