import React from "react";
import { useSelector } from 'react-redux';
import { getToken } from "../utils/HelperFunctions";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, redirectPath}) => {
    const {token, loading } = useSelector((state) => state.user);

    if(loading){
    }

    return (
      getToken()? [children] : <Navigate to='/login' state={redirectPath}/>
      );
}

export default PrivateRoute;