import React, { useState } from 'react'
import '../../styles/signup.css'
import { ConfigProvider } from 'antd';
import { signup } from '../../store/slices/Thunk';
import { useSelector,useDispatch } from 'react-redux';
import { Input, Radio, Button } from 'antd';
import { useNavigate, Link, useLocation } from 'react-router-dom';
const Signup = () => {
    const {token, loading, ssuccess, serror} = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const data = useLocation()
    const [fName, setFname] = useState('')
    const [lName, setLname] = useState('')
    const [phone, setPhone] = useState('')
    const [region, setRegion] = useState('')
    const [location, setLocation] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [sex, setSex] = useState('Male')
    const path = data.state ? data.state : '/'
    console.log(path)
    const confirmInput = document.getElementById('confirm_password')
    if (ssuccess) navigate(path)

    const handleSignup = (e) => {
        e.preventDefault();
        if(confirmPassword !== password ) {
            alert('The two passwords that you entered do not match!')
            return;
        }
        else {
            const address = {region, location}
            const phoneNumber= '+251'+phone
            dispatch(signup({fName, lName, phoneNumber, sex, password, address}))  
        }
        
    }

    return (
        <ConfigProvider
            theme={{
            token: {
                colorPrimary: 'green',
                colorSecondary: 'orange'
            },
            }}
        >
            <div className='signup'>
            <div className='signup_container'>
                <h1>Create new account</h1>
                <div className='signup_form'>
                    <form onSubmit={e => handleSignup(e)}>
                        <div className='signup_input_group'>
                            <p className='input_label'>First name</p>
                            <Input placeholder='First Name' value={fName} onChange={e => setFname(e.target.value)} required/>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>Last name</p>
                            <Input placeholder='Last Name' value={lName} onChange={e => setLname(e.target.value)} required/>
                        </div>
                        <div className='signup_radio_group'>
                            <p className='radio_label'>Sex</p>
                            <Radio.Group onChange={e => setSex(e.target.value)} value={sex}>
                                <Radio value='Male' defaultChecked={true}>Male</Radio>
                                <Radio value='Female'>Female</Radio>
                            </Radio.Group>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>Phone number</p>
                            <Input addonBefore="+251" placeholder='9********' value={phone} onChange={e => setPhone(e.target.value)} required maxLength={9} minLength={9} pattern='^[9]\d{8}'/>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>Region</p>
                            <Input placeholder='Region' value={region} onChange={e => setRegion(e.target.value)} required/>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>City</p>
                            <Input placeholder='City' value={location} onChange={e => setLocation(e.target.value)} required/>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>Password</p>
                            <Input.Password placeholder="***********" value={password} onChange={e => setPassword(e.target.value)} required minLength={8}/>
                        </div>
                        <div className='signup_input_group'>
                            <p className='input_label'>Confirm Password</p>
                            <Input.Password id='confirm_password' placeholder="***********" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required minLength={8}/>
                        </div>
                        <div className='signup_submit'>
                            <Button className='signup_input' type='primary' htmlType="submit" loading={loading}>Sign Up</Button>
                        </div>
                    </form>
                    <div className='signin'>
                        <p>Already have an account? <Link className='link' to='/login'>Sign In</Link></p>
                    </div>
                </div>
            </div>
            </div>
        </ConfigProvider>
        
        
    )
}

export default Signup
