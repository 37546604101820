import React from 'react'
import AboutUs from './AboutUs'
import '../../../styles/about_us_list.css'
const AboutUsList = () => {
  return (
    <div className='about_us_list_container'>
      <h2>We Strive for your Convinence</h2>
      <div className='about_us_list'>
      <AboutUs image='/hero_image/fresh.svg' title='Fresh' description='No Worries! we cover the quality and freshness of the product in our coldroom stores.'/>
      <AboutUs image='/hero_image/payment.svg' title='No Middle Man' description='You can search search, compare and order products from any where.'/>
      <AboutUs image='/hero_image/price.svg' title='Affordable Price' description='You buy directly products from farmers. There is no middle person.'/>
      </div>
    </div>
  )
}

export default AboutUsList
