import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getLocalCart } from '../utils/HelperFunctions'
import { FaTruck } from 'react-icons/fa'
import '../styles/cart_menu.css'
const CartMenu = () => {
    const navigate = useNavigate()
    const getTotalCartItem = () => {
        let totalCartItem = 0
        if(getLocalCart()) {
          totalCartItem = getLocalCart().items.length
        }
        return totalCartItem
      }

      const handleCart = () => {
        navigate('/cart')
    }

    return (
        <div className='cart_icon cart_menu_container' onClick={handleCart}>
            <FaTruck className='cart_icon'/>
            <div className='cart_circle'>
                <p className='cart_circle_text'>{getTotalCartItem()}</p>
            </div>
        </div>
    )
}

export default CartMenu
