import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber} from 'antd'
import { MdDeleteForever } from 'react-icons/md'
import '../../styles/cart_item.css'
const CartItem = ({ item, quantityUpdate, productDelete }) => {

const changeQuantity = (value) => {
  quantityUpdate(item.productId, item.typeId, value)
}

  return (
    <div className='cart_item'>
      <div className='item_image'>
        <img src={item.imageURL} alt=''/>
      </div>
      <div className='item_info'>
        <h3 className='info_text'>{item.productName}</h3>
        <p className='info_text'>Type: {item.typeName}</p>
        <p className='info_text'>Cold room: {item.coldRoomName}</p>
        <p className='info_text'>{item.price} ETB/KG</p>
      </div>
      <div className='quantity_container'>
  <InputNumber className='quantity_input_cart' defaultValue={item.quantity} onChange={changeQuantity} max={item.maximumQuantity} min={1}/>
      </div>
      <div className='delete'>
            <MdDeleteForever className='delete_btn' onClick={() => productDelete(item.productId, item.itemId)}/>
        </div>
    </div>
  )
}

CartItem.propTypes = {
  item : PropTypes.object,
  quantityUpdate : PropTypes.func,
  productDelete : PropTypes.func
}
export default CartItem
