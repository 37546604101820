import React, {useState, useEffect} from 'react'
import { Table, Button, Modal, ConfigProvider } from 'antd'
import { useNavigate } from 'react-router-dom'
import api from '../../service/api'
import OptionMenu from '../../components/OptionMenu'
import { getUserId } from '../../utils/HelperFunctions'
const Order = () => {
  const [orders, setOrders] = useState([])
  const [isPrModalOpen, setIsPrModalOpen] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({})
  const navigate = useNavigate()
  const goShopping = () => {
    navigate('/')
  }
  let locale = {
    emptyText: <div>
      <p className='empty_title'>You don't have any order history</p>
      <Button className='empty_btn' onClick={goShopping}>Continue Shopping</Button>
    </div>,
  };
  useEffect(() => {
    fetchOrders()
  },[])

  const fetchOrders = () => {
    api.get(`/wholesaler/orders/OrderHistory/${getUserId()}`).then( response => {
      console.log(response)
      Object.keys(response.data).length === 0 ? setOrders([]) : setOrders(response.data)  
    }).catch( err => {
      setOrders([])
    })
  }
  
  const cancelOrder = order_id => {
    api.get(`/wholesaler/orders/ChangeStatus/${order_id}`).then( response => {
      fetchOrders()
    }).catch( error => {

    })
  }

  const viewOrderDetail = order_id => {
    setCurrentOrder(orders.find(o => o.id === order_id))
    setIsPrModalOpen(true)
    console.log('view '+order_id)
  }

  

  const handleClose = () => {
    setIsPrModalOpen(false)
  }

  const columns = [
        {
          title: 'Order Code',
          dataIndex: 'orderCode',
          key: 'orderCode',
        },
        {
          title: 'Ordered Date',
          dataIndex: 'orderDate',
          key: 'orderDate',
          render: (text, record) => (
            new Date(text).toISOString().substring(0, 10)
          ),
        },
        {
          title: 'Order Status',
          dataIndex: 'orderStatus',
          key: 'orderStatus',
          responsive: ['md']
        },
        {
          title: 'Payment Status',
          dataIndex: 'paymentStatus',
          key: 'paymentStatus',
          responsive: ['md']
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <OptionMenu orderId={record.id} CancelCallback={cancelOrder} ViewDetailCallback={viewOrderDetail} disabled={record.orderStatus !== 'pending'}/>
          ),
        },
    ]
  
    const itemColumns = [
      {
        title: 'Product',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, record) => (text + ' KG')
      },
      {
        title: 'Total Price',
        key: 'totalPrice',
        render: (_, record) => (
          parseInt(record.price)*parseInt(record.quantity) + ' ETB'
        ),
      }
    ]
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: 'green',
      },
    }}
  >
    <div>
          <h1 className='tab_title'>Order History</h1>
          <p className='tab_detail'>Check the status of recent orders, and follow orders</p>
          <div>
          <Table locale={locale} columns={columns} dataSource={orders}/>
          </div>
          <Modal
            open={isPrModalOpen}
            title="Order Detail"
            onCancel={handleClose}
            footer={[]}
          >
            <div className='order_detail'>
              <hr/>
              <div className='order_detail_info'>
                <p><span>Order Code</span> : {currentOrder.orderCode}</p>
                <p><span>Ordered Date</span> : {currentOrder.orderDate}</p>
                <p><span>Order Status</span> : {currentOrder.orderStatus}</p>
                <p><span>Payment Status</span> : {currentOrder.paymentStatus}</p>
                <p><span>Total Payment</span> : {currentOrder.totalPrice} ETB</p>
                <p><span>Paid Payment</span> : {currentOrder.paidAmount} ETB</p>
              </div>
              <hr/>
              <div className='order_items'>
                <p className='order_item_label'>Items</p>
                <Table columns={itemColumns} dataSource={currentOrder.orderitems}/>
              </div>
            </div>
          </Modal>  
    </div>
  </ConfigProvider>
       
    )
}

export default Order
