import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../../styles/product.css'
import { setProductId } from '../../../utils/HelperFunctions'
const Product = ({ image, name, product_id }) => {
  const navigate = useNavigate()
  const toDetail = () => {
    setProductId(product_id)
    navigate('/product')
  }
  return (
    <div className='product_container' onClick={ () => toDetail() }>
      <div className='product_image_container'>
        <img src={image} alt=''/>
      </div>
      <p>{name}</p>
    </div>
  )
}

export default Product