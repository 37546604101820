import { createSlice } from '@reduxjs/toolkit'
import { redirect, useNavigate } from 'react-router-dom';
import { setToken, getRedirectPath, getToken, setUserId } from '../../utils/HelperFunctions';
import {fetchUserData, login, signOut, signup} from './Thunk';
const initialState = {
    token: null,
    loading: false,
    success: false,
    ssuccess: false,
    serror: false,
    error: false,
    userData: {}
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
        builder
          .addCase(signOut.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = {};
            state.token = null;
          })
          .addCase(login.pending, (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
          })
          .addCase(login.fulfilled, (state, action) => {
            const {accessToken, userData} = action.payload;
            setToken(accessToken)
            setUserId(userData.id)
            console.log(accessToken)
            state.token = accessToken;
            state.userData = userData;
            state.loading = false;
            state.success = true;
            console.log('token from user    '+getToken())
          })
          .addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.error = true;
          })
          .addCase(fetchUserData.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(fetchUserData.fulfilled, (state, action) => {
            const userData = action.payload;
            state.token = getToken();
            state.userData = userData;
            state.loading = false;
          })
          .addCase(fetchUserData.rejected, (state, action) => {
            state.loading = false;
            state.userData = {};
            state.token = null;
          })
          .addCase(signup.pending, (state, action) => {
            state.loading = true
            state.ssuccess = false
            state.serror = false
          })
          .addCase(signup.fulfilled, (state, action) => {
            const {accessToken, user} = action.payload;
            state.token = accessToken
            state.userData = user
            state.loading = false
            state.ssuccess = true;
          })
          .addCase(signup.rejected, (state, action) => {
            state.loading = false
            state.userData = {}
            state.token = null
            state.serror = true
          })
      },
})


export const {} = userSlice.actions;

export default userSlice.reducer;